@charset "utf-8";
@import 'settings';
@import '~foundation-sites/scss/foundation';
@include foundation-everything(true, true);
@import "~animate.css";
@import "@fortawesome/fontawesome-free/css/all.css";
@import '~simplelightbox';
// @import '~file-icon-vectors/dist/file-icon-classic.min.css';
@import 'tiny-slider.scss';
@import '~intl-tel-input/build/css/intlTelInput.css';
@import "custom";
